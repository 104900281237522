@import '../../../styles/customMediaQueries.css';

.root {
  border-radius: 4px;
}

.wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.searchFieldsWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
  flex: 1;
}

.keywordInput {
  width: 100%;
}

.keywordSelectInput {
  height: 100%;
  width: 100%;
  border-radius: 0;
  border: none;
  box-shadow: none;

  &:hover {
    border: none;
  }
}

.searchInput {
  flex-grow: 1;
  height: 100%;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border: 0;
  border-top-width: 2px;

  margin-right: 10px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border: 0;
    outline: none;
    box-shadow: unset;
  }

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }
}
